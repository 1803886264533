let clientName = "Oral-B";
let productName = "Oral-B";
/* Attention, il y a un clientName non-dynamique dans l'optin */

export const nlnl = {

	/* pageTitles are the H2 title at the TOP of each page */
	pageTitles: {
		beforeStart: "Deze actie is nog niet beschikbaar.",
		actionEnded: "Deze actie is helaas afgelopen.",
		maxReached: "Maximum aantal inzendingen bereikt",
		wrongCode: "Verkeerde code",
		wrongCode2: "Verkeerde deelname link",
		alreadySend: "Deelname reeds verstuurd",
		registration: "Inschrijving",
		confirmation: "Bevestiging",
		choiceSurvey: "",
		survey: "Enquête",
		summary: "Besteloverzicht",
		maintenance: "Onderhoud",
		form1: "Ontvang uw cashback nu!", 
		form2: "Wat meer informatie...", 
		wait: "Eventjes geduld…", 
		instructions: "Hoe deel te nemen",
		pageError: "Er is een fout opgetreden",
	},

	/* sectionTitles are titles INSIDE pages */
	/* They should not be arranged by page, because they can move from one to another */
	sectionTitles: {
		wayOfRegistration: "Kies een manier om te registreren",
		thanksForRegistration: "Bedankt voor uw inschrijving!",
		aboutYou: "Vertel ons meer over u",
		address: "Uw adres",
		aboutProduct: "Over ons product",
		ticketUpload: "Aankoopbewijs",
	},

	/* inputsTexts is a list for every label and helpers that Action API don't give. */
	inputsTexts: {
		dropdown : {
			placeholder: "Kies een antwoord…",
			helper: " ",
		},
		title : {
			label: "Aanspreking",
			placeholder: "Hoe kunnen we u noemen?",
			helper: " ",
		},
		gender: {
			label: "Geslacht",
			placeholder: "Hoe definieer je jezelf?",
			helper: " ",
		},
		firstname: {
			label: "Voornaam",
			placeholder : "Mijn voornaam is…",
			helper: " ",
		},
		lastname: {
			label: "Naam",
			placeholder : "Mijn naam is…",
			helper: " ",
		},
		birthdate: {
			label: "Geboortedatum",
			placeholder: "dd/mm/jjjj",
			helper: "",
		},
		email : {
			label: "Gelieve uw e-mail in te vullen",
			placeholder: "naam{'@'}voorbeeld.be",
			helper: "Uw e-mail moet een {'@'} bevatten"
		},
		phone : {
			label: "Gelieve uw GSM-nummer in te vullen",
			placeholder: "Vb: 0477123456",
			helper: " "
		},
		iban : {
			label: "Gelieve uw IBAN nummer in te vullen",
			placeholder: "bv: NL19RABO1234567890",
			helper: "Het nummer moet beginnen met met de landcode (NL)"
		},
		VATNumber: {
			label: "BTW-nummer",
			placeholder: "Vb: BE0123123123",
			helper: "Het nummer moet beginnen met met de landcode (BE/LU/…)"
		},
		googleAPI:{
			label: "Gelieve je volledig adres te zoeken (inclusief huisnummer) om het formulier te kunnen valideren."
		},
		street : {
			label: "Straat",
			labelfacture: "Facturatieadres",
			placeholder: "",
			helper: " "
		},
		houseNumber: {
			label: "Nummer",
			placeholder: "",
			helper: " "
		},
		boxNumber: {
			label: "Bus",
			placeholder: "",
			helper: " "
		},
		postalCode: {
			label: "Postcode",
			placeholder: "",
			helper: " "
		},
		city: {
			label: "Gemeente",
			placeholder: "",
			helper: " "
		},
		country: {
			label: "Land",
			placeholder: "Kies een antwoord…",
			helper: " "
		},
		conditions : {
			part1 : "Ik aanvaard",
			link: "de algemene voorwaarden",
			part3: "."
		},		
		serialNumber : {
			label: "",
			placeholder: "",
			helper: " "
		},
		product: {
			label: "Welk product heeft u aangekocht?",
			placeholder: "Kies een antwoord…",
			helper: " "
		},
		retailer: {
			label: "Waar heeft u het product aangekocht?",
			placeholder: "Kies een antwoord…",
			helper: " "
		},
		freeRetailer: {
			label: `In welke winkel hebt u uw ${productName} producten gekocht?`,
			placeholder: "…",
			helper: " "
		},
		freeInput: {
			label: ``,
			placeholder: "",
			helper: "",
			error_empty: "Gelieve dit veld in te vullen",
			error_wrongValue: "Het antwoord is onjuist",
		},
		datePurchase : {
			label: `Wanneer hebt u uw ${productName} producten gekocht?`,
			placeholder: "jj/mm/aaaa",
			helper: `<strong>PAS OP:</strong> Gebruik de kalender om de datum in te voeren.`,
		},
		uploads: {
			ticket: {
				label: "Klik of sleep je ticket hier."
			},
			barcode: {
				label: "Klik of sleep een foto van de streepjescode hier.",
			},
		},
		optin : {
			part1:"Ik wil graag op de hoogte gehouden worden van ",
			linklabel:`${clientName}`,
			link:"https://us.pg.com/brands/",
			part2:"promoties",
			label : "", /* Should not be visible */	
			helper : "",
			complement: `Ik wil op de hoogte blijven van toekomstige acties van ${clientName}.`
		},
	},

	testPanel: {
		title: `De "verborgen" pagina's`,
		text: "Klik op de onderstaande knoppen om toegang te krijgen tot de formulierpagina's die geen deel uitmaken van de normale deelnamestroom",
		pages: {
			before: "Voor de start",
			after: "Na het einde",
			maxparticip: "Max. deelname",
			wrongcode: "Verkeerde code",
			alreadysent: "Reeds verzonden",
			maintenance: "In onderhoud",
			loading: "Laden…",
		},
		hide: "Verberg me",
		show: "Laat me zien",
	},


	/* 
	ABOVE: WHAT MUST BE USED NOW 
	BELOW: WHAT WAS USED BEFORE 
	*/







	page404: {
		title: "404",
		text: "Deze pagina bestaat niet.",
		back: "Terug naar het formulier",
	},

	errors: {
		emptyField: "Je moet een antwoord geven",
		fieldMissingValue: "Een of meer velden hebben een onjuiste of lege waarde",
		dateInvalid: "De datum is niet geldig",
		dateBefore: "De datum mag geen toekomstige datum zijn",
		dateOutOfPeriod: "De aankoop moet worden gedaan binnen de geldige data voor deze actie",
		wrongcnk : "De CNK code is niet correct",
		fieldsMissing : "Kan de deelname niet verzenden omdat één of meer velden niet ingevuld/valide zijn",
		fieldsMissingTitle: "Lege/ongeldige velden",
		unmanaged: "Er is een onvoorziene fout opgetreden.",
	},

	maintenance: {
		title: "Onderhoud",
		line1 : "Deze website is momenteel in onderhoud en kan niet worden geraadpleegd. Probeer het later nog eens.",
		line2 : "Ons team doet er alles aan om de site zo snel mogelijk weer toegankelijk te maken.",
		thanks : "Dank u voor uw begrip.",
	},

	macaron: {
		big: `100%`,
		small:`terugbetaald`,
	},

	footer: {
		genterms: "Algemene voorwaarden",
		privacy: {
			link: "",
			label: "Privacyverklaring",
		},
		cookies: {
			link: "",
			label: "Cookieverklaring",
		}
	},

	pgFooter: {
		conditions: "Actie voorwaarden",
		/* Below: They appears in the footer as long as there is a link value. */
		privacy: {
			link: "https://www.pg.com/privacy/dutch/privacy_statement.shtml",
			label: "Privacy",
		},
		otherconditions: {
			link:"https://termsandconditions.pg.com/nl-nl/",
			label:"Algemene voorwaarden"
		},
		mycoords: {
			link: "https://preferencecenter.pg.com/nl-nl/",
			label: "Mijn Gegevens",
		},
		contact: {
			link: "https://us.pg.com/contact-us/",
			label: "Contact",
		},
	},

	gdpr: {
		title: "Privacy notice",
		client: `${clientName}`,
	},

	onetrust: {
		cookie: "Beheer cookies",
	},

	navigation: {
		doSurvey: "Antwoorden op de enquête",
		skipSurvey: "Cashback aanvragen",
		previous: "Vorige pagina",
		next: "Volgende pagina",
		claimCashback: "Mijn Cashback aanvragen",
		signup: "Schrijf je nu in!",
		participate: "Deelnemen!",
		toCustomerWebsite: {
			label: "Naar onze website",
			link: ""
		},
		readMoreOnWebsite: "Lees meer",
	},

	breadcrumbSteps: {
		registration: "Inschrijven",
		store: "Doe uw aankopen",
		form: "Vul het formulier in",
		survey: "Vul een optionele vragenlijst in",
		cashback: "Ontvang uw terugbetaling"
	},

	formHeader: {
		bottomText: `* Bij aankoop van Lorem ipsum`,
		macaronText: `terugbetaald!`,
		mainTitle: "Schrijf u in en ontvang uw cashback!"
	},

















	pageLitteracy: {
		pageError: {
			intro: "Er is een fout opgetreden waardoor het formulier niet werkt.",
			solution1 : "Probeer het formulier opnieuw te laden of probeer het later opnieuw..",
			solution2 : `Als de fout blijft bestaan, neem dan contact op met Promolife via <a href="mailto:infolijn{'@'}promolife.be">infolijn{'@'}promolife.be</a> ou au <a href="tel:3223520390">+32 2 352 03 90</a>`,
		},
		instructions : {
			intro: "Instructions de participation comme dans une HAv1. Pas de breadcrumb ici, sinon double emploi (icones, textes).",
			steps: {
				info : "Informatie pagina",
				register: "Als u van plan bent op een later tijdstip deel te nemen, registreer u dan nu, zodat u een herinnering ontvangt.",
				store: {
					line1a: "Haast u naar de winkel tussen",
					line1b: "en",
					line2: "Koop een MARQUEMACHIN product(en).",
					line3: "Houd uw kassaticket of factuur goed bij.",
				},
				form: {
					line1: "Vul uw gegevens in.",
					line2: "Neem het aankoopbewijs van uw MARQUEMACHIN product(en) erbij en omcirkel de datum, het aangekocht product en de prijs.",
					line3: "Scan of neem een foto van uw volledig aankoopbewijs.",
					line4: "Upload deze foto of scan.",
				},
				survey: "Doe een optionele enquête en ontvang een kortingsbon.",
				cashback: "Eens we uw deelname goedgekeurd hebben, ontvangt u uw terugbetaling binnen de 6 weken.",
			}
		},
		maxReached : "Het maximale aantal deelnames is bereikt. Deze actie is daarom niet meer toegankelijk.",	
		wrongCode: {
			text: `De opgegeven code is verkeerd. U moet de link gebruiken die u door ${clientName} wordt toegestuurd.`,
			textregistration: "Maar misschien wil je je inschrijven voor de actie?",
			errorWithReparticipation: `Er is een fout opgetreden met uw deelname link.<br>Neem contact op met onze technische teams door een e-mail te sturen naar <a href="mailto:infolijn{'@'}promolife.be">infolijn{'@'}promolife.be</a> met onderstaande informatie:`
			/* ! Missing translation */
		},
		alreadySend: "U heeft uw deelname al verzonden",
		registration : {
			byRegistering: "Door me in te schrijven voor deze actie, ga ik akkoord met de",
			privacyNotice: "Privacy notice",
			claimMail : "Inschrijven via e-mail",
			claimWhatsapp: "Inschrijven via Whatsapp",
			claimSms: "Inschrijven via SMS",
			or: "Of",
			whatsappTitle: "Klik op de Whatsapp knop",
			whatsappInfo: {
				part1: `Een venster wordt geopend en u wordt gevraagd om het woord`,
				part2: `van uit uw Whatsapp te verzenden.`
			},
		},
		confirmRegistration: {
			smsLine1 :"U zal spoedig een SMS ontvangen met alle details.",
			smsLine2: "Ga eens kijken!",
			whatsappLine1: "Controleer uw telefoon.",
			whatsappLine2: "Alle instructies zijn verstuurd via Whatsapp",
			mailLine1: "U zal spoedig een e-mail ontvangen met alle details.",
			mailLine2: "Ga eens kijken!",
		},
		form1: {
			legal: ``,
		},
		choiceSurvey: {
			title:'',
			fillSurveyIncite: "Neem deel aan onze enquête en krijg een e-coupon van €1 voor uw volgende aankoop",
		},
		survey: {
			title: "Bedankt voor het beantwoorden van deze vragen",
			about: "Enquête",
		},
		confirmation: {
			title: "Bevestiging",
			textes: {
				p1: `Wij danken u voor uw deelname aan de ${clientName} actie.`,
				p2: "U ontvangt uw terugbetaling binnen de 6 weken, mits u aan de algemene voorwaarden heeft voldaan.",
				coupon: "We hebben je kortingsbon naar het opgegeven e-mailadres verzonden. Geniet ervan!",
				p3: "Met vriendelijke groeten,",
				p4: `Het ${clientName} team`
			},
		},
		actionEnded: {
			text: "Bezoek onze website regelmatig om op de hoogte te blijven van toekomstige acties."
		},
		beforeStart: {
			text: "U krijgt toegang vanaf ",
			shouldBeginSoon: "De actie moet spoedig beginnen. Kom later nog eens terug. "
		},
	},









	inputLabel: {
		defaultListHelper: "Kies er één uit de lijst",
		mandatoryFields: "Verplichte velden",
		noResult: "Geen resultaat",





		empty : {
			label: "",
			placeholder: "",
			helper: ""
		},
		cnk1 : {
			label: `Wat is de CNK code van het gekochte ${clientName} product?`,
			placeholder: "0000-000",
			helper: "Het format is: XXXX-XXX",
			error: "De CNK code is niet correct",
		},



      //https://vue-i18n.intlify.dev/guide/essentials/syntax.html#literal-interpolation

/*
      typePassword : "Gelieve je wachtwoord in te vullen",
      helpPassword : "Je wachtwoord moet X, Y en Z bevatten",
      typeUniqueCode : "Indiquez ici le code visible sur le ticket de caisse",
      */

		upload: {
			state: "status",
			uploadYourFile: "Upload bestand",
			clickOrDrop: "Klik of sleep je ticket hier.",
			rules: "Afbeelding moet een bestand zijn van het bestandstype JPG, JPEG, PNG, BMP, GIF, SVG, WEBP, PDF",
			remove: "Verwijder",
			waiting: "Wachten op upload...",
			uploaded: "Geüpload!",
			error: "Mislukt",
			uploadsDone: "Upload succesvol afgerond",
			onlyImages: "Enkel afbeeldingen worden aanvaard.",
			maxSize: "Maximale grootte van 20 MB",
			uploadFile: "Upload bestand",
			generalError: "Er ging iets mis met uw upload, gelieve later opnieuw te proberen"
		}
	},
}
